import React from "react";
import styles from "../styles/Main.module.scss";
import { AiOutlineMenu } from "react-icons/ai";
import { FaFacebook, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
function Home() {
  const variants = {
    init: {
      opacity: 0,
    },
    anim: {
      opacity: 1,
      transition: {
        staggerChildren: 0.8,
      },
    },
  };
  const navItems = {
    init: {
      y: -300,
    },
    anim: {
      y: 0,
      transition: {
        duration: 0.2,
        type: "spring",
        stiffness: 50,
      },
    },
  };
  return (
    <div className={styles.main_home_land}>
      <div className={styles.handle_navBar}>
        <motion.nav initial="init" animate="anim" variants={variants}>
          <motion.div variants={navItems}>
            <h2>LoGo</h2>
          </motion.div>
          <motion.ul>
            <motion.li variants={navItems}>
              <NavLink activeClassName="" to="/">
                iBrandup
              </NavLink>
            </motion.li>
            <motion.li variants={navItems}>About</motion.li>
            <motion.li variants={navItems}>Services</motion.li>
            <motion.li variants={navItems}>Contact</motion.li>
          </motion.ul>
          <motion.div variants={navItems}>
            <span>
              <AiOutlineMenu />
            </span>
          </motion.div>
        </motion.nav>
      </div>
      <div className={styles.motivated_parent}>
        <div className={styles.motivated}>
          <h1>
            <i>Be</i> <span>S</span>
            <i className={styles.__i}>ucceed</i>
            <br />
            forever
          </h1>
        </div>
        <div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            <br />
            Voluptatum ducimus vitae, voluptatibus
            <br />
            laboriosam rem culpa sunt voluptates ipsa eaque dicta nam et.
            <br />
            Excepturi tenetur voluptatibus quis commodi nosstrum assumenda.
          </p>
        </div>
      </div>
      <div className={styles.social_btns}>
        <div className={styles.social_btns_child}>
          <div className={styles.social_mediaIcons}>
            <div>
              <span className={styles.span_icons}>
                <FaFacebook />
              </span>
            </div>
            <div className={styles.span_icons}>
              <span>
                <FaWhatsapp />
              </span>
            </div>
            <div className={styles.span_icons}>
              <span>
                <FaTwitter />
              </span>
            </div>
          </div>
          <div>
            <button>Discover More ...</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
