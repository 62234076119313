import React, { Suspense, useRef, useEffect } from "react";
import styles from "../styles/three.module.scss";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useGLTF, Stars, Html } from "@react-three/drei";
import { motion } from "framer-motion";
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";
const MyModel = () => {
  const gtlf = useGLTF("/scene.gltf", true);
  console.log(gtlf);
  return (
    <primitive object={gtlf.scene} scale={[100, 100, 100]} dispose={null} />
  );
};
const MyLights = () => {
  //this function to show and present the gtlf to camera place
  return (
    <>
      <ambientLight intensity={0.4} />
      <directionalLight color="red" position={[10, 10, 16]} intensity={8} />
      <directionalLight color="red" position={[0, 10, 0]} intensity={1} />
      <spotLight intensity={1} position={[1200, 2, 0]} />
      <OrbitControls />
    </>
  );
};
const Vars = (argV) => {
  const Variants = {
    init: {
      opacity: 0,
      x: -400,
    },
    anim: {
      opacity: 1,
      x: -80,
      transition: {
        type: "spring",
        stiffness: 60,
        delay: argV,
      },
    },
  };
  return Variants;
};
const variants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      staggerChildren: 0.8,
    },
  },
};
const navItems = {
  init: {
    y: -300,
  },
  anim: {
    y: 0,
    transition: {
      duration: 0.2,
      type: "spring",
      stiffness: 50,
    },
  },
};
function HtmlContent() {
  const refer = useRef();
  useFrame(
    () => ((refer.current.rotation.y += 0.01), (refer.current.rotation.x = 0.8))
  );
  return (
    <group>
      <mesh ref={refer} scale={[1, 1, 1]} position={[0, -5.3, 0]}>
        <MyModel />
      </mesh>
      <Html fullscreen>
        <div className={styles.container}>
          <div className={styles.handle_navBar}>
            <motion.nav initial="init" animate="anim" variants={variants}>
              <motion.ul>
                <motion.li
                  whileHover={{ scale: 1.1, cursor: "pointer" }}
                  variants={navItems}
                >
                  iCoffee
                </motion.li>
                <motion.li
                  whileHover={{ scale: 1.1, cursor: "pointer" }}
                  variants={navItems}
                >
                  About
                </motion.li>
                <motion.li
                  whileHover={{ scale: 1.1, cursor: "pointer" }}
                  variants={navItems}
                >
                  Services
                </motion.li>
                <motion.li
                  whileHover={{ scale: 1.1, cursor: "pointer" }}
                  variants={navItems}
                >
                  Contact
                </motion.li>
              </motion.ul>
            </motion.nav>
          </div>
          <div className={styles.content__Three}>
            <motion.div className={styles.social_Media_sthree}>
              <motion.div
                initial="init"
                animate="anim"
                variants={Vars(1.1)}
                whileHover={{ scale: 1.1, cursor: "pointer" }}
              >
                <span>
                  <FaFacebook />
                </span>
              </motion.div>
              <motion.div
                initial="init"
                animate="anim"
                variants={Vars(1.3)}
                whileHover={{ scale: 1.1, cursor: "pointer" }}
              >
                <span>
                  <FaInstagram />
                </span>
              </motion.div>
              <motion.div
                initial="init"
                animate="anim"
                variants={Vars(1.5)}
                whileHover={{ scale: 1.1, cursor: "pointer" }}
              >
                <span>
                  <FaYoutube />
                </span>
              </motion.div>
              <motion.div
                initial="init"
                animate="anim"
                variants={Vars(1.7)}
                whileHover={{ scale: 1.1, cursor: "pointer" }}
              >
                <span>
                  <FaWhatsapp />
                </span>
              </motion.div>
            </motion.div>
            <div className={styles.title}>
              <motion.h1
                initial={{ opacity: 0, y: -400 }}
                animate={{
                  opacity: 1,
                  y: -110,
                  transition: {
                    type: "spring",
                    stiffness: 70,
                  },
                }}
              >
                Start Your Day
              </motion.h1>
              <motion.h1
                initial={{ opacity: 0, y: -400 }}
                animate={{
                  opacity: 1,
                  y: 140,
                  transition: {
                    type: "spring",
                    stiffness: 70,
                  },
                }}
              >
                With A Cup Of Coffee
              </motion.h1>
            </div>
          </div>
        </div>
      </Html>
    </group>
  );
}
const SpinningMesh = ({ position, args, color }) => {
  const Mosh = useRef(null);
  useFrame(() => (Mosh.current.rotation.y = Mosh.current.rotation.x += 0.01));
  return (
    <mesh position={position} ref={Mosh}>
      <boxBufferGeometry attach="geometry" args={args} />
      <meshStandardMaterial attach="material" color={color} />
    </mesh>
  );
};
const SpinningMeshCircle = ({ position, args, color }) => {
  const Mosh = useRef(null);
  useFrame(() => (Mosh.current.rotation.y = Mosh.current.rotation.x += 0.01));
  return (
    <mesh position={position} ref={Mosh}>
      <circleBufferGeometry attach="geometry" args={args} />
      <meshStandardMaterial attach="material" color={color} />
    </mesh>
  );
};
function Three() {
  return (
    <div>
      <div className={styles.parent_three}>
        <div className={styles.canvas_One}>
          <Canvas colorManagement camera={{ position: [0, -2, 20], fov: 70 }}>
            <Stars />
            <Suspense fallback={null}>
              <ambientLight intensity={1} />
              <HtmlContent />
              <SpinningMesh
                position={[-2, 8, -5]}
                args={[3, 3, 5]}
                color="#550907"
              />
              <SpinningMesh
                position={[-5, 1, 10]}
                args={[1, 1, 1]}
                color="#6f4e37"
                roughness={5}
                metalness={1}
              />
              <SpinningMesh
                position={[-5, -7, 10]}
                args={[1, 1, 1]}
                color="#6f4e37"
              />
              <SpinningMesh
                position={[5, 1, 10]}
                args={[1, 1, 1]}
                color="#6f4e37"
              />
              <SpinningMesh
                position={[5, -7, 10]}
                args={[1, 1, 1]}
                color="#6f4e37"
              />
            </Suspense>
            <MyLights />
          </Canvas>
        </div>
      </div>
    </div>
  );
}

export default Three;
