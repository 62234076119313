import styles from "./styles/Main.module.scss";
import Home from "./components/home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DemoOne from "./components/demoOne";
import Three from "./components/Three";
function App() {
  return (
    <div className={styles.App}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/demo-one" component={Home} />
          <Route exact path="/" component={DemoOne} />
          <Route exact path="/threeJs" component={Three} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
